import React from "react"
import Link from "gatsby-plugin-transition-link/AniLink"

export default function Intent({ children, to }) {
  return (
    <Link
    // 1
    // cover bg="#FF8A00"
    // 1
    // 2
      fade
      duration={0.2}
      // 2
      // 3
      // swipe top="entry"
      // swipe top="exit"
      // entryOffset={80}
      // 3
      // 4
      // paintDrip color="rebeccapurple"
      // 4
      // 5
      // direction="left"
      // duration={3}
      // bg="
      //   url(https://source.unsplash.com/random)
      //   center / cover   /* position / size */
      //   no-repeat        /* repeat */
      //   fixed            /* attachment */
      //   padding-box      /* origin */
      //   content-box      /* clip */
      //   white            /* color */
      // "
      // 5
      className="nav-link"
      to={to}
      activeStyle={{
        color: "white",
      }}
      style={{
        color: "grey",
      }}
    >
      {children}
    </Link>
  )
}
