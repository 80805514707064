import React from "react"
import logoIMG from "../../assets/img/ic-logo-white.png"
import instagramICON from '../../assets/img/icons/instagram.svg';
import waICON from '../../assets/img/icons/wa.svg';
import mailICON from '../../assets/img/icons/mail.svg';
import facebookICON from '../../assets/img/icons/facebook.svg';

const Footer = () => {
    return (
        <footer className="upper">
            <div className="wrapper">
                <h1 className="title yellow-font">
                    Caso prefira, entre em contato por WhatsApp:
                </h1>
                <br />
                <a className="social">
                    <a href="https://api.whatsapp.com/send?1=pt_BR&phone=555199920906&text=Oi,Gifthy !" target="_blank" rel="noreferrer"><img src={waICON} alt="WhatsApp" /></a>
                </a>
                <br />
                <img className="img" src={logoIMG} alt="logo" />
                <br />
                {/* TODO: revert this when we have all links */}
                {/* <div className="links">
                    <article>
                        <h3 className="white-font font-bold">Aplicativos</h3>
                        <div className="list">
                            <span className="white-font">
                                Android & Chrome OS
                            </span>
                            <span className="white-font">Apple</span>
                            <span className="white-font">Web</span>
                        </div>
                    </article>
                    <article>
                        <h3 className="white-font font-bold">Institucional</h3>
                        <div className="list">
                            <span className="white-font">
                                Política de privacidade
                            </span>
                            <span className="white-font">Termos de uso</span>
                            <span className="white-font">FAQs</span>
                        </div>
                    </article>
                    <article>
                        <h3 className="white-font font-bold">Comunidade</h3>
                        <div className="list">
                            <span className="white-font">API</span>
                            <span className="white-font">Desenvoldedores</span>
                            <span className="white-font">
                                Meios de Pagamento
                            </span>
                        </div>
                    </article>
                    <article>
                        <h3 className="white-font font-bold">Aplicativos</h3>
                        <div className="list">
                            <span className="white-font">Sobre</span>
                            <span className="white-font">Imprensa</span>
                            <span className="white-font">Carreiras</span>
                        </div>
                    </article>
                </div> */}
                <div className="info">
                    <div className="wrapper">
                        <span className="white-font">
                            GIFTHY NETWORK SERVICOS DE INTERMEDIAÇÃO
                        </span>
                        <span className="white-font">
                            DE NEGÓCIOS DE INFORMÁTICA LTDA
                        </span>
                        <span className="white-font">
                            CNPJ: 36.959.425/0001-70
                        </span>
                        <a
                            href="mailto:contato@gifthy.com.br"
                            className="white-font"
                            target="_blank"
                        >
                            contato@gifthy.com.br
                        </a>
                        <span className="white-font white-font-align-left" >
                            Porto Alegre - RS - Brazil <br />
                            WhatsApp: <br />
                            <a
                                href="https://api.whatsapp.com/send?1=pt_BR&phone=555199920906&text=Oi,Gifthy !"
                                className="white-font"
                                target="_blank"
                            >
                                &bull; (51) 99992-0906
                            </a>
                            <br />
                            <a
                                href="https://api.whatsapp.com/send?1=pt_BR&phone=5551981846790&text=Oi,Gifthy !"
                                className="white-font"
                                target="_blank"
                            >
                                &bull; (51) 98184-6790 
                            </a>
                        </span>
                    </div>
                </div>
                <br />
                <div className="info">
                    <div className="icons">
                        <a href="https://www.instagram.com/gifthybrasil/" target="_blank" rel="noreferrer"><img src={instagramICON} alt="instagram" /></a>
                        <a href="mailto:contato@gifthy.com.br" target="_blank" rel="noreferrer"><img src={mailICON} alt="email" /></a>
                        <a href="https://www.facebook.com/gifthybrasil" target="_blank" rel="noreferrer"><img src={facebookICON} alt="facebook" /></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
