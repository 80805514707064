import React from "react"

const Bottom = () => {
    return (
        <footer className="bottom">
            <div className="wrapper">
                <span>
                    Copyright © {new Date().getFullYear()} Gifthy. All Right
                    Reserved
                </span>
            </div>
        </footer>
    )
}
export default Bottom
