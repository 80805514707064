import React from "react"
import Top from "./Top"
import Bottom from "./Bottom"

const Footer = () => {
    return (
        <div className="footer">
            <Top />
            <Bottom />
        </div>
    )
}
export default Footer
