import React from "react"
import Intent from "./link"
import { Helmet } from "react-helmet"

import logoIMG from "../../../assets/img/header/menu/ic-logo.png"
import instagramICON from "../../../assets/img/icons/instagram-white.svg"
import waICON from "../../../assets/img/icons/wa-white.svg"
import facebookICON from "../../../assets/img/icons/facebook-white.svg"

const MenuItems = props => {
    const infoPage = props.infoPage
    const page = infoPage.page
    const subPage = infoPage.sub ? infoPage.sub : false

    const handlePageName = () => {
        let allName = page.charAt(0).toUpperCase() + page.slice(1)
        if (subPage) {
            allName =
                allName +
                " - " +
                subPage.charAt(0).toUpperCase() +
                subPage.slice(1)
        }

        allName = allName + ` | Gifthy`
        return allName
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{handlePageName()}</title>
                <link rel="canonical" href="https://gifthy.com.br" />
            </Helmet>
            <header className={!props.isMobile ? "menu" : "menu mobile"}>
                <nav>
                    <div className={props.isMobile ? "display-none" : null}>
                        <Intent to="/">
                            <img
                                src={logoIMG}
                                alt="Crafty Logo"
                                className="logo"
                            />
                        </Intent>
                    </div>
                    <div className="d-f-a-c">
                        <a href="#inicio" className="blue-font font-bold">
                            <span className="button">Início</span>
                        </a>
                        <a href="#por-que" className="blue-font font-bold">
                            <span className="button">Por que</span>
                        </a>
                        <a href="#faq" className="blue-font font-bold">
                            <span className="button">FAQs</span>
                        </a>
                        <a href="#contato" className="blue-font font-bold">
                            <span className="button">Contato</span>
                        </a>
                        <div className="auth__menus">
                            <a href={`https://app.gifthy.com.br/login`} target="_blank">
                                <span className="button">ENTRAR</span>
                            </a>
                            <span className="blue-font font-bold">ou</span>
                            <a href={`https://app.gifthy.com.br/cadastre-se`} target="_blank">
                                <span className="button">CRIAR CONTA</span>
                            </a>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default MenuItems
